// This is useful for tracking user interactions with a page. For example,
// you could use this to track how many times a user clicks a button.

{/* 
<button data-controller="click-track" data-click-track-url-value="/my_tracker_endpoint" data-action="click-track#track">
  Some important button
</button>
*/}


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    hasClickedAlready: { type: Boolean, default: false },
    url: String,
  }

  connect() {
    console.info("Connected to the click track controller")
    console.info(this.urlValue)
  }
  
  track() {
    if (!this.hasClickedAlreadyValue) {
      fetch(this.urlValue, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaValue("csrf-token")
        },
      }).then(() => {
        this.hasClickedAlreadyValue = true
      })
    }
  }
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}