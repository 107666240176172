import { Controller } from "@hotwired/stimulus"

// Ensures we clean up the remnants of ADT's TrustedForm implementation
// before navigating away from the Home Security offer page

export default class extends Controller {
  connect() {
    console.info("ADT TrustedForm Controller: connected", this.element);
  }
  
  disconnect() {
    document.querySelector('[src*="cdn.trustedform.com"]')?.remove();
    document.getElementById("trusted-form-noscript")?.remove();
    trustedFormStopRecording();
    console.info('ADT TrustedForm Controller: disconnected');
  }
}