import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Resize controller connected")
    this.resizeEnabled = this.element.dataset.isNative
    if (this.resizeEnabled) {
      this.initialMarginBottom = this.element.style.marginBottom
      this.element.style.marginBottom = '260px'
    }
  }

  focus(event) {
    if (this.resizeEnabled) {
      event.target.style['scroll-margin-top'] = '25px'
      event.target.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
}
