import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "hiddenInput", "total", "years", "plan", "addons", "planTypeInput", "planTimeInput", "hiddenQuantityInput", "display" ]
  static values = {num: { type: Number, default: 0 }}
  
  PLANS ={
    "plan_platinum": 550,
    "plan_gold": 480,
    "plan_bronze": 420,
    "plan_multiple": 80
  }
  
  YEARS = {
    "plan_time_1": 1,
    "plan_time_2": 2,
    "plan_time_3": 3,
    "plan_time_5": 5,
  }
  
  ADDONS = {
    "additional_ac_unit": 70.00,
    "additional_heat_furnace": 69.99,
    "additional_water_heater": 49.99,
    "central_vacuum": 39.99,
    "ice_maker_in_refrigerator": 39.99,
    "lawn_sprinkler_system": 69.99,
    "lighting_fixtures_plumbing_fixtures": 49.99,
    "pool": 99.95,
    "roof_leak": 0.00,
    "salt_water_pool": 179.99,
    "second_refrigerator": 39.99,
    "septic_system": 69.99,
    "spa": 69.99,
    "standalone_freezer": 39.99,
    "sump_pump": 39.99,
    "tankless_water_heater": 89.99,
    "water_softener": 79.99,
    "well_pump": 39.99
  }

  totalAddons = 0
  addonsPrice = 0
  currentYearsSelected
  currentPlanSelected

  connect() {
    this.currentYearsSelected = this.YEARS['plan_time_1']
    this.currentPlanSelected = this.PLANS['plan_platinum']
    this.totalTarget.textContent = `$ ${this.currentPlanSelected.toFixed(2)}`
    this.addonsTarget.textContent = "$ 0.00"
    this.planTarget.textContent = `$ ${this.currentPlanSelected.toFixed(2)}`
    this.yearsTarget.textContent = `${this.currentYearsSelected} Year${this.currentYearsSelected > 1 ? 's' : ''}`
    this.numValue = this.hiddenInputTarget.value
    this.changeValue(this.numValue)
  }

  handleQuantityChange(event) {
    const key = event.params.key;
    const operation = event.params.operation;
    const hiddenInput = this.hiddenQuantityInputTargets.find(input => input.dataset.key === key);
    const display = this.displayTargets.find(span => span.dataset.policyRequestTotalsKeyParam === key);

    if (hiddenInput && display) {
      let currentValue = parseInt(hiddenInput.value, 10) || 0;
      if (operation === "plus") {
        currentValue++;
      } else if (operation === "minus" && currentValue > 0) {
        currentValue--;
      }
      hiddenInput.value = currentValue;
      display.textContent = currentValue;
      const addonPrice = this.ADDONS[key] || 0;
      this.totalAddons += addonPrice;
      this.addonsTarget.textContent = `$ ${this.totalAddons.toFixed(2)}`;
      this.calculateTotals(this.totalAddons);
    }
  }
  
  changedYearsRadio(e) {
    let value = `${this.YEARS[e.target.id]} Year${this.YEARS[e.target.id] > 1 ? 's' : ''}`
    this.yearsTarget.textContent = value;
    this.currentYearsSelected = this.YEARS[e.target.id];
    this.calculateTotals(this.totalAddons)
  }

  changedPlanRadio(e) {
    this.planTarget.textContent = `$ ${this.PLANS[e.target.id].toFixed(2)}`
    this.currentPlanSelected = this.PLANS[e.target.id]
    this.calculateTotals(this.totalAddons)
  }

  changedCheckbox(e) {
    if (e.target.checked) {
      this.totalAddons += this.ADDONS[e.target.id]
    } else {
      this.totalAddons = this.totalAddons == 0 ? this.totalAddons : this.totalAddons - this.ADDONS[e.target.id]
    }
    this.addonsTarget.textContent =  `$ ${this.totalAddons.toFixed(2)}`;
    this.calculateTotals(this.totalAddons)
  }

  calculateTotals(totalAddons) {
    this.totalTarget.textContent = `$ ${((totalAddons + this.currentPlanSelected) * this.currentYearsSelected ).toFixed(2)}`
  }
  
  plus() {
    this.changeValue(this.numValue + 1)

  }
  
  minus() {
    this.changeValue(Math.max(this.numValue -1, 0))

  }
  
  changeValue(value) {


  }
}