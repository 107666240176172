// The user can click a button and copy text to the clipboard

// <div data-controller="clipboard" data-clipboard-success-content-value="Copied!">
//   <div data-clipboard-target="source">
//     Inner text to be copied
//   </div>
//   <button data-action="clipboard#copy" data-clipboard-target="button">
//     Click me to copy
//   </button>
// </div>


// ...the `buttonTarget` does not need to necessarily be a button. For example...

// <button data-action="clipboard#copy">
//   <svg id="icon-of-some-sort"></svg>
//   <span data-clipboard-target="button">Click me to copy</span>
// </button>


// ...and the clipboard target can be an input field as well...

// <input data-clipboard-target="source" value="Value to be copied">

import { Controller } from "@hotwired/stimulus"

export default class Clipboard extends Controller {
  static targets = ["button", "source"]
  static values = {
    successContent: String,
    successDuration: {
      type: Number,
      default: 2000,
    },
  }

  connect() {
    console.info("Hello from clipboard controller")
    if (!this.hasButtonTarget) return

    this.originalContent = this.buttonTarget.innerHTML
  }

  copy(event) {
    event.preventDefault()

    const text = (this.sourceTarget.innerHTML || this.sourceTarget.value).trim()

    navigator.clipboard.writeText(text).then(() => this.copied())
  }

  copied() {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = this.successContentValue

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent
    }, this.successDurationValue)
  }
}