import { Controller } from "@hotwired/stimulus";
import parsePhoneNumber, { AsYouType, validatePhoneNumberLength, isValidPhoneNumber } from 'libphonenumber-js';

export default class extends Controller { 
  phone_input = document.getElementById('submit-policy-phone');
  zip = document.getElementById('sumbit-policy-zip');
  phone_number;

  connect() {
    this.phone_input && this.parsePhone();
    this.zip && this.parseZip();
  }

  parsePhone () {
    this.phone_input.addEventListener('keyup', (e) => {
      if (validatePhoneNumberLength(e.target.value) === 'TOO_LONG') {
        this.phone_input.value = this.phone_number
        return
      }
      let parsedPhone = new AsYouType('US').input(e.target.value)
      
      if (isValidPhoneNumber(parsedPhone, 'US')) {
        parsedPhone = parsePhoneNumber(parsedPhone, 'US').formatNational()
      }
      this.phone_number = parsedPhone
      this.phone_input.value = this.phone_number
    })
  }

  parseZip() {
    this.zip.addEventListener('keyup', (e) => this.zip.value = e.target.value.length < 5 ? e.target.value : e.target.value.substring(0, 5))
  }



}